import React from 'react';
import {useSelector} from "react-redux";
import NavBar from "./NavBar";

// The Layout component handels the Navbar and what is displayed in there
export default function Layout() {
    const rkLists = useSelector((state) => state.reducer.rkLists)
    return (
        <NavBar
            rkList={rkLists}
        />
    );
}




