import React from "react";
import {Route, Switch} from "react-router-dom";
import {
    routes as allRoutes,
} from "./index";

// Helper function to render all routes
const childRoutes = (routes) =>
    routes.map(({path, component: Component}, index) =>
        (
            <Route
                key={index}
                path={path}
                exact
                component={Component}
            />
        )
    );

const Routes = () =>
    (

        <Switch>
            {childRoutes(allRoutes)}
        </Switch>

    );

export default Routes;
