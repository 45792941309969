import client from "./flaskClient";
import portalClient from "./portalClient";


const getProject = (id) => client.get(`/get/calculation/${id}`);
const postProject = (data) => client.post(`/post/general/edit`, data);
const postCalculation = (id, data) => client.post(`/post/calculation/edit`, {id, data});

const getRKList = (id) => client.get(`/get/calculation/${id}/ebs`)
const postRK = (id, rkNum) => client.post(`/post/ebs/new`, {id, rkNum})
const editRK = (id, calId, name, active) => client.post(`/post/ebs/edit`, {id, calId, name, active})
const copyRK = (id, rkId, rkName, rkNum) => client.post(`/post/ebs/copy`, {id, rkId, rkName, rkNum})
const deleteRK = (id, calId) => client.post(`/post/ebs/delete`, {id, calId})
const gotEm = (id) => client.post(`/post/gotm`, {id})

const getNAW = (searchTerm) => client.get(`/naw?q=${searchTerm}`)

const getEngineering = (id) => client.get(`/get/calculation/${id}/engineering`)
const postUrenoverzicht = (data) => client.post('/post/hours/edit', data)

const getTotalHandlerInfo = (id) => client.get(`/get/calculation/${id}/total`)

const getTermijnen = (id) => client.get(`/get/terms/${id}`)
const postTermijnen = (data) => client.post(`/post/termijnen/save`, data)

const postExtraHours = (id, data) => client.post(`/post/add_hours/save`, {id, data})

const postVoorbrief = (data) => client.post('/post/voorbrief/save', data)

const sendFinanceInfo = (id, data) => portalClient.put(`/sales_offers/${id}/finance`, data);

const getPms = () => client.get('/pms')
const getSls = () => client.get('/sls')

const postTotalProperties = (id, data) => client.post(`/post/total_properties/save`, {id, data})
const postTotalSave = ( data) => client.post(`/post/totals/save`, data)

const postIndexingValues = (id, data) => client.post(`/post/index/${id}`, data)



const projectApi = {
    getProject,
    postProject,
    getRKList,
    postRK,
    editRK,
    copyRK,
    deleteRK,
    getNAW,
    postCalculation,
    getEngineering,
    getTotalHandlerInfo,
    getTermijnen,
    postTermijnen,
    postUrenoverzicht,
    postVoorbrief,
    gotEm,
    sendFinanceInfo,
    postExtraHours,
    getPms,
    getSls,
    postTotalProperties,
    postTotalSave,
    postIndexingValues
};

export default projectApi