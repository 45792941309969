import {create} from "apisauce";
import {getCookie} from "../utility/cookies";

// create an API object from the apisauce module and set the base URL
const apiClient = create({
    baseURL: process.env.REACT_APP_PORTAL_URL,
    headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
    },
});

// Add the authentication token from the cookie to every request
apiClient.addAsyncRequestTransform(async (request) => {

    const authToken = getCookie("ycon_auth0_token")
    if (!authToken) return;
    if (!request.headers["authorization"]) request.headers["authorization"] = "Bearer " + authToken;

});

export default apiClient;