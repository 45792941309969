import React from "react";
import {Container, Row} from "reactstrap";
import {Spinner} from "react-bootstrap";

// This component displays the loader
const Loader = () => (
    <Container fluid className="vh-50 d-flex">
        <Row className="justify-content-center align-self-center w-100 text-center">
            <Spinner
                style={{
                    color: "#292073",
                }}
                animation="border"
            />
        </Row>
    </Container>
);

export default Loader;
