import async from "../components/Async";

const Overzicht = async(() => import("../pages/Overzicht"));
const Table = async(() => import("../pages/Table"));
const Voorbrief = async(() => import("../pages/Voorbrief"));
const PO = async(() => import("../pages/PO"));
const Options = async(() => import("../pages/Opties"));
const Engineering = async(() => import("../pages/Engineering"));
const Totalen = async(() => import("../pages/TotaalHandler"));
const TotalenPrint = async(() => import("../components/totalen/TotalenPrint"));
const Print = async(() => import("../pages/Print"));


const OverzichtRoute = {
    path: "/:id/overzicht",
    name: "Overzicht",
    containsHome: true,
    component: Overzicht
};

const TableRoute = {
    path: "/:pid/:id/:type/edit",
    name: "Table",
    component: Table

};

const VoorbriefRoute = {
    path: "/:id/voorbrief",
    name: "Voorbrief",
    component: Voorbrief
};

const PORoute = {
    path: "/:id/po",
    name: "PO",
    component: PO
};
const OptionsRoute = {
    path: "/:id/opties",
    name: "opties",
    component: Options

};
const EngineeringRoute = {
    path: "/:id/engineering",
    name: "Engineering",
    component: Engineering

};
const TotalenRoute = {
    path: "/:id/totalen",
    name: "Totalen",
    component: Totalen

};

const TotalenPrintRoute = {
    path: "/:id/totalen/print",
    name: "Totalen",
    component: TotalenPrint

};

const PrintRoute = {
    path: "/:id/print",
    name: "Print",
    component: Print

};


// Dashboard specific routes
export const routes = [
    OverzichtRoute,
    TableRoute,
    VoorbriefRoute,
    PORoute,
    EngineeringRoute,
    TotalenRoute,
    TotalenPrintRoute,
    PrintRoute,
    OptionsRoute
];


