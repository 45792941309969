import React, {useEffect, useState} from "react";
import {Beforeunload} from 'react-beforeunload';
import {Route, useHistory, withRouter} from "react-router-dom";
import {useSelector, useDispatch} from "react-redux";
import * as actionTypes from "./store/actions";

import Header from "./components/layoutComponents/Header";
import Layout from "./components/layoutComponents/Layout";


import NotFound from "./pages/notFound";

import {Spinner} from "react-bootstrap";
import ReduxToastr from "react-redux-toastr";
import "react-redux-toastr/lib/css/react-redux-toastr.min.css";
import {setCookie} from './utility/cookies'
import projectApi from './api/projectApi'
import ProjectContext from "./utility/context";
import Routes from "./routes/Routes";
import "./components/engineering/eng_ibs.css";
import "./components/table/Table.css";
import {useAuth0} from "@auth0/auth0-react";
import jwtDecode from "jwt-decode";


const App = () => {
    const history = useHistory()

    // Auth0
    const {user, isAuthenticated, isLoading, loginWithRedirect, getAccessTokenSilently} = useAuth0();
    // App context
    const [project, setProject] = useState(false)

    // Redux
    const loader = useSelector((state) => state.reducer.loader)
    const cRK = useSelector((state) => state.reducer.currentRK)
    const dispatch = useDispatch()

    // When the user is not authenticated, redirect to the login page
    useEffect(() => {
        if (!isLoading) {
            if (!isAuthenticated) redirectToLogin()
            else checkAuth()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user, isAuthenticated, isLoading])

    // Get the current path and save it in localStorage for the redirect after login
    const redirectToLogin = async () => {
        if (history.location.pathname !== '/' && history.location.pathname !== '/logout') {
            localStorage.setItem('path', history.location.pathname)

            history.push('/')
            loginWithRedirect({redirectUri: history.location.origin})
        } else if (history.location.pathname === '/logout') history.push('/logout')
        else history.push('/404')
    }
    // If the user is authenticated get the path from localStorage and redirect to it after that load the project
    const checkAuth = async () => {
        const pathname = localStorage.getItem("path")
        if (pathname) {
            history.push(pathname)
            localStorage.removeItem("path")
        }
        const accessToken = await getAccessTokenSilently()
        if (jwtDecode(accessToken).permissions.includes("write:templates")) dispatch({type: actionTypes.SET_ADMIN})
        setCookie('ycon_auth0_token', accessToken, 1 / 3)
        loadProject()

    }

    // Load the project from the API
    const loadProject = async () => {
        // This parts takes the id from the weblink and sends get requests for the project and the RK lists if either of those fails a 404 is given
        let cPath = history.location.pathname.split("/");
        let linkID = ''

        for (let x of cPath) {
            if (x.length === 36) {
                linkID = x;
                break;
            }
        }

        if (linkID !== "") {
            const resList = await projectApi.getRKList(linkID)
            if (!resList.ok) return null
            dispatch({type: actionTypes.GET_RKLIST, rkList: resList.data})

            const resProject = await projectApi.getProject(linkID)
            if (!resProject.ok) return null
            setProject(resProject.data)

        } else {
            history.replace("/404");
        }
    }

    // While the project is loading show a spinner
    if (isLoading) return <Spinner
        style={{
            marginTop: "25%",
            marginLeft: "50%",
            color: "#292073",
        }}
        animation="border"/>

    // If path is /404 show the not found page
    if (history.location.pathname === '/404') return <Route component={NotFound}/>

    return (
        // Context provider which allows the project to be accessed from any component and to change the project and reload the project
        <ProjectContext.Provider value={{project, setProject, reload: loadProject}}>

            {(project) ? (
                <>
                    {/*Prompt so the person will not accidentally close the calculation*/}
                    <Beforeunload onBeforeunload={() => ''}/>
                    <Header current={cRK}/>
                    {/*Main layout which is on every page*/}
                    <div className="layout layout-nav-side">
                        <Layout/>
                        {/*Routes are loaded here*/}
                        <Routes/>
                    </div>
                    {loader && (<div className="overlay-loading">
                            <Spinner
                                style={{
                                    marginTop: "25%",
                                    marginLeft: "50%",
                                    color: "#292073",
                                }}
                                animation="border"
                            />
                        </div>
                    )}
                </>
            ) : null}
            <ReduxToastr
                newestOnTop={true}
                position="top-right"
                transitionIn="fadeIn"
                transitionOut="fadeOut"
                closeOnToastrClick
            />
        </ProjectContext.Provider>
    );
}


export default withRouter(App);