import * as actionTypes from "./actions";
import {produce} from "immer";

// This is where the actions which are dispatched by redux are coded and it also contains the redux store

// The initial state also known as the store
const initialState = {
    authenticated: true,
    id: null,
    projectBool: false,
    rkLists: [],
    rowData: null,
    productList: null,
    currentRK: null,
    loader: false,
    rkModalList: [],
    admin: false,
};

// The reducer depending on the action makes changes to the store
const reducer = (state = initialState, action) => {
    switch (action.type) {
        // stores from the action the rklist
        case actionTypes.GET_RKLIST:
            return produce(state, (draftState) => {
                draftState.rkLists = action.rkList;
            });

        // stores the current row data in the store for the searchtool
        case actionTypes.SAVE_DATA:
            return produce(state, (draftState) => {
                draftState.rowData = action.data;
            });

        // sets the current RK the user is on for the title
        case actionTypes.SET_RK:
            return produce(state, (draftState) => {
                draftState.currentRK = action.data;
            });

        // This handles the overlay loader
        case actionTypes.SET_LOADER:
            return produce(state, (draftState) => {
                draftState.loader = !draftState.loader;
            });

        case actionTypes.SET_UURTARIEVEN:
            return produce(state, (draftState) => {
                draftState.uurTarieven = action.data;
            });

        case actionTypes.ADD_RK_MODAL:
            return produce(state, (draftState) => {
                draftState.rkModalList.push(action.data);
            });
        case actionTypes.SET_RK_MODAL:
            return produce(state, (draftState) => {
                draftState.rkModalList = (action.data);
            });
        case actionTypes.DELETE_RK_MODAL:
            return produce(state, (draftState) => {
                draftState.rkModalList = draftState.rkModalList.filter((item) => item.key !== action.data);
            });
        case actionTypes.RESET_RK_MODAL:
            return produce(state, (draftState) => {
                draftState.rkModalList = [];
            });

        case actionTypes.SET_ADMIN:
            return produce(state, (draftState) => {
                draftState.admin = true;
            });

        // The default is to return the state
        default:
            return state;
    }
};

export default reducer;
