import React, {useContext} from 'react'
import ProjectContext from "../../utility/context";

// Simple header component
const Header = (props) => {
    const {project} = useContext(ProjectContext)


    return (
        // title changes based on which rk you are working on
        <React.Fragment>
            <title>{props.current} {project.calculation_reference} {project.calculation_name} </title>
        </React.Fragment>
    )
}

export default Header