import React, {useEffect, useState, useContext} from "react";
import {NavLink, useLocation} from "react-router-dom";
import ProjectContext from "../../utility/context"
import {useAuth0} from "@auth0/auth0-react";

// HTML code for the Navbar on the side
// It receives data as props from the layout component and displays them
const NavBar = (props) => {
    const {project} = useContext(ProjectContext)
    const {logout} = useAuth0();
    let location = useLocation();
    const [isTemplate, setIsTemplate] = useState("")

    // This function checks if the current location is a template and sets the state accordingly
    useEffect(() => {
        for (let i of location.pathname.split('/')) {
            if (
                i === 'f00' ||
                i === 'n00' ||
                i === 'r00' ||
                i === 'netto00' ||
                i === 'nar00'
            ) return setIsTemplate(i)
        }
        return setIsTemplate('')
    }, [location])

    // If the page is a template don't show any links
    if (isTemplate) return <div className="navbar navbar-expand-lg bg-dark bg-dark-blue navbar-dark sticky-top">
        {project.offer_country === "NL" ? <img src="/assets/img/Nordomatic logo_NEG.png" width="150" alt={""}/>
            : <img src="/assets/img/ibess-logo-footer.png" width="150" alt={""}/>}
        <div className="collapse navbar-collapse flex-column" id="navbar-collapse" style={{paddingTop: "15px"}}>
            <ul className="navbar-nav d-lg-block">
                <span className="text-muted">{project.calculation_name}</span>
                <br/>
                <span className="text-small text-muted">{project.calculation_reference}</span>
            </ul>
        </div>
        <h3 className={"text-white"}>Template {isTemplate}</h3>
    </div>

    // If the page is not a template show the links
    return (
        <div className="navbar navbar-expand-lg bg-dark bg-dark-blue navbar-dark sticky-top">
            {project.offer_country === "NL" ? <img src="/assets/img/Nordomatic logo_NEG.png" width="150" alt={""}/>
                : <img src="/assets/img/ibess-logo-footer.png" width="150" alt={""}/>}
            <div className="d-flex align-items-center">
                <button
                    className="navbar-toggler"
                    type="button"
                    data-toggle="collapse"
                    data-target="#navbar-collapse"
                    aria-controls="navbar-collapse"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                >
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="d-block d-lg-none ml-2">
                    <div className="dropdown">
                        <div className="dropdown-menu dropdown-menu-right"></div>
                    </div>
                </div>
            </div>
            <div className="collapse navbar-collapse flex-column" id="navbar-collapse" style={{paddingTop: "15px"}}>
                <ul className="navbar-nav d-lg-block">
                    <span className="text-muted">{project.calculation_name}</span>
                    <br></br>
                    <span className="text-small text-muted">{project.calculation_reference}</span>

                    <hr/>

                    <li className="nav-item">
                        <NavLink className="nav-link" to={`/${project.calculation_id}/overzicht`}>
                            Overzicht
                        </NavLink>
                    </li>
                    {project.field_equipment ? (
                        <li className="nav-item">
                            <a
                                href="# "
                                className="nav-link"
                                data-toggle="collapse"
                                aria-expanded="false"
                                data-target="#submenu-2"
                                aria-controls="submenu-2"
                            >
                                Veldapparatuur
                            </a>
                            <div id="submenu-2" className="collapse">
                                <ul className="nav nav-small flex-column">
                                    {
                                        props.rkList.map(rk => {
                                            let classString = ['nav-link']
                                            if (rk.eb_active) return (<NavLink key={rk.calculation_eb_id} className={classString.join(' ')}
                                                                               to={`/${project.calculation_id}/${rk.calculation_eb_id}/f/edit`}> {rk.eb_name}
                                                <span className={(rk.fst) ? 'dot-status' : null}></span> </NavLink>)
                                            return null
                                        })
                                    }
                                </ul>
                            </div>
                        </li>
                    ) : null}

                    {project.ddc_equipment ? (
                        <li className="nav-item">
                            <a
                                href="# "
                                className="nav-link"
                                data-toggle="collapse"
                                aria-expanded="false"
                                data-target="#submenu-3"
                                aria-controls="submenu-3"
                            >
                                Regelapparatuur
                            </a>
                            <div id="submenu-3" className="collapse">
                                <ul className="nav nav-small flex-column">
                                    {
                                        props.rkList.map(rk => {
                                            let classString = ['nav-link']
                                            if (rk.eb_active) return (
                                                <NavLink
                                                    key={rk.calculation_eb_id}
                                                    className={classString.join(' ')}
                                                    to={`/${project.calculation_id}/${rk.calculation_eb_id}/n/edit`}>{rk.eb_name}
                                                    <span className={(rk.nst) ? 'dot-status' : ''}/></NavLink>)
                                            return null
                                        })
                                    }
                                </ul>
                            </div>
                        </li>
                    ) : null}

                    {project.eb || project.eb_conversion ? (
                        <li className="nav-item">
                            <a
                                href="# "
                                className="nav-link"
                                data-toggle="collapse"
                                aria-expanded="false"
                                data-target="#submenu-4"
                                aria-controls="submenu-4"
                            >
                                Regelkasten
                            </a>
                            <div id="submenu-4" className="collapse">
                                <ul className="nav nav-small flex-column">
                                    {
                                        props.rkList.map(rk => {
                                            let classString = ['nav-link']
                                            if (rk.eb_active) return (
                                                <NavLink
                                                    key={rk.calculation_eb_id}
                                                    className={classString.join(' ')}
                                                    to={`/${project.calculation_id}/${rk.calculation_eb_id}/r/edit`}>{rk.eb_name}
                                                    <span className={rk.rst ? 'dot-status' : ''}/>
                                                </NavLink>)
                                            return null

                                        })
                                    }
                                </ul>
                            </div>
                        </li>
                    ) : null}

                    {project.room_controllers ? (
                        <li className="nav-item">
                            <a
                                href="# "
                                className="nav-link"
                                data-toggle="collapse"
                                aria-expanded="false"
                                data-target="#submenu-5"
                                aria-controls="submenu-5"
                            >
                                Naregelingen
                            </a>
                            <div id="submenu-5" className="collapse">
                                <ul className="nav nav-small flex-column">
                                    {
                                        props.rkList.map(rk => {
                                            let classString = ['nav-link']
                                            if (rk.eb_active) return (
                                                <NavLink
                                                    key={rk.calculation_eb_id} className={classString.join(' ')}
                                                    to={`/${project.calculation_id}/${rk.calculation_eb_id}/nar/edit`}>
                                                    {rk.eb_name}
                                                    <span className={rk.narst ? 'dot-status' : ''}/>
                                                </NavLink>)
                                            return null

                                        })
                                    }
                                </ul>
                            </div>
                        </li>
                    ) : null}

                    {project.net_equipment ? (
                        <li className="nav-item">
                            <NavLink to={`/${project.calculation_id}/netto-table/netto/edit`} className="nav-link">
                                Netto Apparatuur
                            </NavLink>
                        </li>
                    ) : null}
                </ul>
                <hr/>
                <div className="navbar-nav d-lg-block w-100">
                    <span className="text-small text-muted">Extra's</span>
                    <ul className="nav nav-small flex-column mt-2">
                        <li className="nav-item">
                            <NavLink to={`/${project.calculation_id}/engineering`} className="nav-link">
                                Urenoverzicht
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink to={`/${project.calculation_id}/totalen`} className="nav-link">
                                Totalen
                            </NavLink>
                        </li>
                        {project.pricing_options && <li className="nav-item">
                            <NavLink to={`/${project.calculation_id}/opties`} className="nav-link">
                                Opties
                            </NavLink>
                        </li>}
                        <li className="nav-item">
                            <NavLink to={`/${project.calculation_id}/voorbrief`} className="nav-link">
                                Voorbrief
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink to={`/${project.calculation_id}/po`} className="nav-link">
                                Prijsoverzicht
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink to={`/${project.calculation_id}/print`} className="nav-link">
                                Print
                            </NavLink>
                        </li>
                    </ul>
                    <hr/>
                </div>
                <div className="navbar-nav d-lg-block w-100">

                    <li className="nav-item">
                        <p
                            className={`nav-link navtempl ${isTemplate}`}
                            data-toggle="collapse"
                            aria-expanded="false"
                            data-target="#submenu-temp"
                            aria-controls="submenu-temp"
                        >
                            Templates
                        </p>
                        <div id="submenu-temp" className="collapse">
                            <ul className="nav nav-small flex-column">
                                <li className="nav-item">
                                    <NavLink to={`/${project.calculation_id}/templates/f00/edit`} target="_blank" className="nav-link navtempl">
                                        Veldapparatuur
                                    </NavLink>
                                </li>

                                <li className="nav-item">
                                    <NavLink to={`/${project.calculation_id}/templates/n00/edit`} target="_blank" className="nav-link navtempl">
                                        Regelapparatuur
                                    </NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink to={`/${project.calculation_id}/templates/nar00/edit`} target="_blank" className="nav-link navtempl">
                                        Naregelingen
                                    </NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink to={`/${project.calculation_id}/templates/netto00/edit`} target="_blank" className="nav-link navtempl">
                                        Netto Apparatuur
                                    </NavLink>
                                </li>
                            </ul>
                        </div>
                    </li>
                    <hr/>
                </div>
            </div>

            <ul className="nav nav-small flex-column">
                <li className="nav-item">
                    <p className="nav-link" onClick={logout}>Logout</p>
                </li>
            </ul>
        </div>
    );

};

export default NavBar;
