import React from "react";
import ReactDOM from "react-dom";
import {BrowserRouter} from "react-router-dom";

import {createStore, combineReducers} from "redux";
import {Provider} from "react-redux";
import {reducer as toastr} from "react-redux-toastr";

import {Auth0Provider} from "@auth0/auth0-react";
import {getCookie} from "./utility/cookies";
import reducer from "./store/reducer";
import axios from "axios";

import App from "./App";


// The index file connects the DOM to the React components and to the redux store

// Axios interceptors to add the Authorization header with the cookie to every request
axios.interceptors.request.use(
    (request) => {
        if (request.url !== `${process.env.REACT_APP_PORTAL_URL}/auth/refresh_token`) {
            request.headers.authorization = "Bearer " + getCookie("ycon_auth0_token");

            return request
        } else {
            return request
        }

    },
    (error) => {
        return Promise.reject(error);
    }
);
axios.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        return Promise.reject(error);
    }
);

// Redux store combined with the toastr to show messages
const reducers = combineReducers({
    reducer,
    toastr,
});


const store = createStore(reducers);

// Auth0 wrapper for authentication
ReactDOM.render(
    <Auth0Provider domain={process.env.REACT_APP_AUTH0_DOMAIN}
                   clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
                   redirectUri={window.location.origin}
                   audience={process.env.REACT_APP_AUTH0_AUDIENCE}>
        <Provider store={store}>

            <BrowserRouter>
                <App/>
            </BrowserRouter>
        </Provider>
    </Auth0Provider>,
    document.getElementById("app")
);
